import axios from "../axios";
import { IdoListType } from "../../utils/types";

export const GetIdo = async () => {
  //console.log("Get Ido API call!");
  try {
    const endPoint = "api/ido/";
    const res = await axios.get<Array<IdoListType>>(endPoint);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get Ido list API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error while CreateIdo API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getIdoDetail = async (id: string) => {
  //console.log("Get Ido API call!");
  try {
    const endPoint = "api/ido/" + id;
    const res = await axios.get<IdoListType>(endPoint);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get CreateIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error getIdoDetail  API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const buyAllocation = async (id: string, payload: any) => {
  try {
    const endPoint = "api/ido/" + id + "/buy-allocation/";
    const res = await axios.post<any>(endPoint, payload);
    if (!res?.data) throw "Something went wrong";
    //console.log("Get CreateIdo API response", res);
    return res.data;
  } catch (err: any) {
    console.log("error getIdoDetail  API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getAllocations = async () => {
  try {
    const endPoint = "api/allocations/";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error getIdoDetail  API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getUserParticipating = async (id: number) => {
  try {
    const endPoint = `api/ido/${id}/user-opt-in-status/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error get Opt In Status  API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const optInUser = async (id: number) => {
  try {
    const endPoint = `api/ido/${id}/opt-in/`;
    const res = await axios.post<any>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error OptIn  API>>>", err.response.data);
    return Promise.reject(err);
  }
};

export const getIdoStage = async (id: number) => {
  try {
    const endPoint = `api/ido/${id}/ido-stage/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error getIdoStage  API>>>", err.response.data);
    return Promise.reject(err);
  }
};
