import { ConfigProvider } from "antd";
import App from "./App";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { arbitrum, mainnet, sepolia, avalanche, linea, base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { breakPoints } from "./utils/constants";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "4b6c5a37d94dc1cd55aae4056bd850ae";

// 2. Create wagmiConfig
const metadata = {
  name: "Private Round Offers available to Retail | Acquire New Ventures",
  description: "new-ventures.org offers retail users access to deals usually only available to VC's.",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum, sepolia, avalanche, linea, base] as const;

export const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

const ThemedApp = () => {
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakPoints;
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              screenXS,
              screenXSMin: screenXS,
              screenXSMax: screenSM - 1,
              screenSM,
              screenSMMin: screenSM,
              screenSMMax: screenMD - 1,
              screenMD,
              screenMDMin: screenMD,
              screenMDMax: screenLG - 1,
              screenLG,
              screenLGMin: screenLG,
              screenLGMax: screenXL - 1,
              screenXL,
              screenXLMin: screenXL,
              screenXLMax: screenXXL - 1,
              screenXXL,
              screenXXLMin: screenXXL,
            },

            components: {
              Menu: {
                itemBg: "transparent",
                colorText: "rgba(255, 255, 255, 0.50)",
                fontSize: 16,
                fontFamily: "Montserrat",
                itemBorderRadius: 10,
                itemSelectedBg: "#28204E",
                itemSelectedColor: "white",
                itemMarginBlock: 8,
                iconMarginInlineEnd: 6,
                itemPaddingInline: "12px",
              },
              Button: {
                paddingBlockLG: 12,
                controlHeightLG: 44,
                fontSize: 16,
                fontFamily: "Montserrat",
                colorText: "#000",
                fontWeight: 500,
                borderRadiusLG: 10,
                ///////Default Button/////////
                defaultBg: "transparent",
                defaultBorderColor: "#33FFFF",
                defaultColor: "rgba(255, 255, 255, 0.50)",
                defaultHoverBg: "transparent",
                defaultHoverColor: "rgba(255, 255, 255, 0.50)",

                ////Primary Button//////
                colorPrimary: "#33FFFF",
                colorPrimaryHover: "#18D1D1",
                primaryColor: "#000000",
                colorTextLightSolid: "#000000",
                colorPrimaryActive: "#18D1D1",
              },
              Select: {
                selectorBg: "rgba(255, 255, 255, 0.10)",
                colorBorder: "transparent",
                colorBgElevated: "#0C0A1F",
                controlHeightLG: 44,
                borderRadiusLG: 10,
                colorText: "white",
                fontSize: 16,
                optionFontSize: 16,
                fontFamily: "Montserrat",
                fontSizeIcon: 18,
                colorPrimaryHover: "transparent",
                colorPrimary: "transparent",
                optionSelectedBg: "rgba(255, 255, 255, 0.05)",
                colorTextPlaceholder: "white",
              },
              Slider: {
                handleColor: "#33FFFF",
                trackBg: "#33FFFF",
                trackHoverBg: "#33FFFF",
                dotActiveBorderColor: "#33FFFF",
                dotBorderColor: "#33FFFF",
                handleActiveColor: "#33FFFF",
                colorPrimaryBorderHover: "#33FFFF",
                handleSize: 12,
                railBg: "#0C0A1F",
                railHoverBg: "#0C0A1F",
                railSize: 8,
                borderRadiusXS: 5,
              },
              Drawer: {
                colorBgElevated: "#0C0A1F",
              },
              InputNumber: {
                activeBg: "transparent",
                activeBorderColor: "transparent",
                handleBg: "transparent",
                handleBorderColor: "transparent",
                handleActiveBg: "#33FFFF",
                handleHoverColor: "transparent",
                hoverBg: "transparent",
                hoverBorderColor: "transparent",
                paddingBlock: 0,
                paddingInline: 0,
                inputFontSize: 28,
                colorBgContainer: "transparent",
                colorBorder: "transparent",
                colorText: "#FFF",
                fontFamily: "Montserrat",
                activeShadow: "none",
                controlWidth: 140,
              },
              Table: {
                colorBgContainer: "#120f2d",
                headerSplitColor: "transparent",
                headerColor: "#7C7C82",
                headerBg: "#120F2D",
                headerBorderRadius: 12,
                borderColor: "rgba(255, 255, 255, 0.250)",
                rowExpandedBg: "rgba(255, 255, 255, 0.05)",
                cellPaddingBlockSM: 12,
              },
              Pagination: {
                itemActiveBg: "#353535",
                colorText: "#909090",
                colorPrimary: "#33FFFF",
              },
              Input: {
                activeBg: "#0C0A1F",
                activeBorderColor: "#424242",
                hoverBg: "#0C0A1F",
                hoverBorderColor: "#424242",
                colorBgContainer: "rgba(255, 255, 255, 0.1)",
                colorBorder: "#424242",
                colorIcon: "#424242",
                colorText: "#FFF",
                colorTextPlaceholder: "#424242",
                addonBg: "rgba(255, 255, 255, 0.04)",
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default ThemedApp;
