import React, { useState, useEffect } from "react";

interface CountdownInterface {
  endDateString: string;
}

function Countdown(props: CountdownInterface) {
  const { endDateString } = props;

  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      const endDate = new Date(Date.parse(endDateString));
      const now = new Date();
      const timeDifference = endDate.getTime() - now.getTime();

      if (timeDifference < 0) {
        setRemainingTime("Closed");
        return;
      }

      const seconds = Math.floor(timeDifference / 1000) % 60;
      const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

      setRemainingTime(formattedTime);
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <>{remainingTime}</>;
}

export default Countdown;

interface CountdownInterface {
  endDateString: string;
}

export function CountdownForTileCard(props: CountdownInterface) {
  const { endDateString } = props;

  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const parseDateString = (dateString: string) => {
      const [date, time, modifier] = dateString.split(" ");
      const [day, month, year] = date.split("/");
      let [hours, minutes] = time.split(":");
      const seconds = "00"; // Assuming the seconds are not provided, default to "00"

      if (modifier.toUpperCase() === "PM" && hours !== "12") {
        hours = (parseInt(hours, 10) + 12).toString();
      }
      if (modifier.toUpperCase() === "AM" && hours === "12") {
        hours = "00";
      }

      // Constructing a Date object in the format 'YYYY-MM-DDTHH:MM:SS'
      return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
    };

    const updateCountdown = () => {
      const endDate = parseDateString(endDateString);
      const now = new Date();
      const timeDifference = endDate.getTime() - now.getTime();

      if (timeDifference < 0) {
        setRemainingTime("Closed");
        return;
      }

      const seconds = Math.floor(timeDifference / 1000) % 60;
      const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

      setRemainingTime(formattedTime);
    };

    updateCountdown();

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [endDateString]);

  return <>{remainingTime}</>;
}
