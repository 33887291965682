import { Button, Col, Layout, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import SingleIdoDetailCard from "../components/SinglePageIdoComponent/SingleIdoDetailCard";
import ParticipateCard from "../components/SinglePageIdoComponent/ParticipateCard";
import ProjectOverviewCard from "../components/SinglePageIdoComponent/ProjectOverviewCard";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useNavigate, useParams } from "react-router-dom";
import { getIdoDetail } from "../api/ApiCalls";
import { IdoListType } from "../utils/types";
import { Spin } from "antd";
import Text from "../components/Text";
import { useAccount, useSwitchChain } from "wagmi";

const SignlePageIdo = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { id } = useParams<any>();
  const [ido, setIdo] = useState<IdoListType>();
  const navigate = useNavigate();
  const { switchChain } = useSwitchChain();
  const { chain } = useAccount();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getIdo = async () => {
    try {
      const res = await getIdoDetail(id || "");
      setIdo(res);
    } catch (err) {
      console.log("Error While Get Offer ", err);
    }
  };

  useEffect(() => {
    getIdo();
  }, []);

  useEffect(() => {
    if (ido?.network.chain_id !== chain?.id) {
      switchChain({ chainId: ido?.network.chain_id || 1 });
    }
  }, [ido, chain]);

  return (
    <>
      <Layout
        style={{
          backgroundColor: "#0C0A1F",
          padding: xs ? "24px 16px" : "24px 30px",
        }}
      >
        {ido ? (
          <>
            <Row style={{ gap: "15px" }}>
              <Col
                style={{
                  width: xxl ? "860px" : xl ? "676px" : lg ? "580px" : "100%",
                }}
              >
                <SingleIdoDetailCard ido={ido} getIdo={getIdo} />
              </Col>
              <Col style={{ flex: "1", maxWidth: lg ? "706px" : "100%" }}>
                <ParticipateCard
                  ido={ido}
                  getIdo={getIdo}
                  setIsModalOpen={setIsModalOpen}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "36px",
                width: xxl ? "860px" : xl ? "676px" : lg ? "580px" : "100%",
                paddingBottom: "30px",
              }}
            >
              <ProjectOverviewCard ido={ido} />
            </Row>
          </>
        ) : (
          <Row
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </Row>
        )}
      </Layout>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={xs ? 300 : 530}
        footer={false}
        closable={false}
        styles={{
          content: {
            padding: "36px",
            background: "#120F2D",
            border: "1px solid rgba(255, 255, 255, 0.250)",
            borderRadius: "24px",
          },
        }}
      >
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Text
            size="sm"
            style={{
              fontSize: "20px",
              marginBottom: "12px",
              width: "100%",
              textAlign: "center",
            }}
          >
            Please verify your account
          </Text>
          <Text
            size="sm"
            style={{ textAlign: "center", marginBottom: "24px", width: "1005" }}
          >
            You cannot participate in this offer until you have verified your
            account.
          </Text>
          <Button
            style={{ width: "100%" }}
            type="primary"
            size="large"
            onClick={() => {
              handleCancel();
              navigate("/account");
            }}
          >
            Verify account
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default SignlePageIdo;
