import { Col, Row, Button, Select, MenuProps, Avatar, Drawer } from "antd";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId } from "wagmi";
import logo from "../assets/Svgs/Logo.svg";
import logomobile from "../assets/Svgs/Logomobile.svg";
import { IconWallet } from "./Icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import SideBar, { SideBarMenu } from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  appState,
  closeSidebar,
  openSidebar,
  userState,
} from "../redux/reducers";
import { useNavigate } from "react-router-dom";
import { getNetworks } from "../api/ApiCalls/common";
import { useSwitchChain } from "wagmi";
import { Network } from "../utils";

const Header = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const dispatch = useDispatch();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [openDrawer, setOpenDrawer] = useState(false);
  const SideBar = useSelector(appState);
  const navigate = useNavigate();

  const showDrawer = () => {
    if (!SideBar.MobileSider) {
      dispatch(openSidebar());
    }
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#0C0A1F",
          padding: xs ? "16px 16px" : "16px 30px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.250)",
        }}
      >
        <Col style={{ display: "flex", alignItems: "center", gap: "120px" }}>
          <img
            src={xs ? logomobile : logo}
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {/* {md && (
            <Button size="large" style={{ marginRight: "20px" }}>
              Buy $ACQ Token
            </Button>
          )} */}
          {md && address && <SelectComponent />}
          <Col style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {!address && (
              <Button
                type="primary"
                size="large"
                onClick={() => open({ view: "Networks" })}
              >
                Connect Wallet <IconWallet />
              </Button>
            )}
            {address && (
              <Button size="large" type="primary" onClick={() => open()}>
                {shortenString(address)}
              </Button>
            )}
            <MenuOutlined
              style={{
                color: "white",
                height: "24px",
                width: "24px",
                display: md ? "none" : "",
              }}
              onClick={showDrawer}
            />
          </Col>
        </Col>
      </Row>
      <Drawer
        width={294}
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        key={"left"}
      >
        <SideBarMenu />
      </Drawer>
    </>
  );
};

export default Header;

function shortenString(str: any, maxLength = 10) {
  if (str.length <= maxLength * 2) {
    return str;
  }
  const halfLength = Math.floor(maxLength / 2);
  return str.slice(0, halfLength) + "..." + str.slice(-halfLength);
}

interface SelectProps {
  style?: React.CSSProperties;
  width?: number | string;
}

export const SelectComponent = (prop: SelectProps) => {
  const { style, width } = prop;
  const [networks, setNetworks] = useState<any>([]);
  const chain_id = useChainId();
  const [activeChain, setActiveChain] = useState<any>(null);
  const { chains, switchChain } = useSwitchChain();
  const { token } = useSelector(userState);
  const { address, connector } = useAccount();
  const [selectedNetwork, setSelectedNetwork] = useState<any>({});

  const handleChange = async (value: string) => {
    const chain: Network = await networks.find(
      (network: Network) => network.symbol === value
    );

    if (chain) {
      switchChain({ chainId: chain.chain_id });
      setActiveChain(chain);
    }
  };

  const loadData = async () => {
    const data = await getNetworks();

    if (chain_id) {
      const chain: Network = networks.find(
        (network: Network) => network.chain_id === chain_id
      );

      if (chain) {
        switchChain({ chainId: chain.chain_id });
        setActiveChain(chain);
      }
    }

    setNetworks(data);
  };

  useEffect(() => {
    if (networks.length) {
      const chain: Network = networks.find(
        (network: Network) => network.chain_id === chain_id
      );

      if (chain) {
        setSelectedNetwork(chain);
      }
    }
  }, [networks, chain_id]);

  useEffect(() => {
    if (address && token) {
      loadData();
    }
  }, [token]);

  return (
    <Select
      size="large"
      value={selectedNetwork?.symbol}
      placeholder={selectedNetwork?.name || "Select Network"}
      style={{ width: width ? width : 196 }}
      onChange={handleChange}
    >
      {networks.length &&
        networks.map((item: any, index: number) => (
          <Select.Option key={index} value={item?.symbol} label={item?.name}>
            <Row
              style={{
                alignItems: "center",
                gap: "6px",
                height: "44px",
              }}
            >
              <img
                src={item.network_icon}
                style={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  background: "white",
                  padding: "3px",
                }}
              />
              {item.name}
            </Row>
          </Select.Option>
        ))}
    </Select>
  );
};
