import axios from "../axios";

export const getNetworks = async () => {
  try {
    const response = await axios.get("/api/networks/");

    return response.data;
  } catch (err) {
    console.log("getNetworks", err);
    return Promise.reject(err);
  }
};
