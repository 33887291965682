import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
const { Text: AntText } = Typography;

interface textProps extends TextProps {
  size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

const Text = (props: textProps) => {
  const { color = "White", size, ...rest } = props;
  const getVariantStyle = () => {
    switch (size) {
      case "xs":
        return {
          fontSize: 12,
          fontWeight: 500,
          fontFamily: "Montserrat",
        };
      case "sm":
        return {
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "Montserrat",
        };
      case "md":
        return {
          fontSize: 16,
          fontWeight: 500,
          fontFamily: "Montserrat",
        };
      case "xxl":
        return {
          fontSize: 45,
          fontWeight: 600,
          fontFamily: "Montserrat",
        };
    }
  };
  return (
    <AntText
      {...rest}
      style={{
        lineHeight: "normal",
        ...getVariantStyle(),
        color: color,
        ...props.style,
      }}
    >
      {props.children}
    </AntText>
  );
};

export default Text;
