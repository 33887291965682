import axios from "../axios";

interface GetChallengeRes {
  data: string;
  status: string;
}

export const GetChallenge = async (wallet_address: string) => {
  try {
    const endPoint = `api/user/challenge/?wallet_address=${wallet_address}`;
    const res = await axios.get<GetChallengeRes>(endPoint);
    //   console.log("Api GetChallenge", res.data);
    return res.data;
  } catch (err) {
    console.log("error while get signature", err);
    return Promise.reject(err);
  }
};

interface WalletLogin {
  access_token: string;
  refresh_token: string;
  user_id: number;
  wallet_address: string;
}
interface GetWalletLogin {
  data: WalletLogin;
  status: string;
}

export const WalletLogin = async (payload: any) => {
  try {
    const endPoint = "api/user/login/";
    const res = await axios.post<GetWalletLogin>(endPoint, payload);
    if (!res?.data) throw "Something went wrong";
    return res.data.data;
  } catch (err) {
    console.log("error post wallet", err);
    return Promise.reject(err);
  }
};

export const GetUser = async (): Promise<any> => {
  try {
    const endPoint = "/api/user/current/";
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw "Something went wrong GetUser";
    console.log("get user", res.data);
    return res.data;
  } catch (err) {
    console.log("error get user", err);
    return Promise.reject(err);
  }
};

export const GetSumsubAccessToken = async () => {
  try {
    const response = await axios.get("/api/user/generate-sumsub-access-token/");
    console.log("GetSumsub access token", response.data);
    return response.data.access_token;
  } catch (err) {
    console.log("error sumsub", err);
    return Promise.reject(err);
  }
};

export const setUserEmail = async (email: string) => {
  try {
    const response = await axios.post("/api/user/verify-user/", {
      email: email,
    });
    console.log("verify user", response.data);
    return response.data;
  } catch (err) {
    console.log("error sumsub", err);
    return Promise.reject(err);
  }
};

export const verifyUserEmail = async (token: string) => {
  try {
    const response = await axios.get(`/api/user/verify-user/?token=${token}`);
    console.log("verify user", response.data);
    return response.data;
  } catch (err) {
    console.log("error sumsub", err);
    return Promise.reject(err);
  }
};

export const submitUserAnswers = async (userId: number, answers: any) => {
  try {
    const endPoint = `/api/user/submit-answers/${userId}/`;
    const res = await axios.post(endPoint, answers);
    console.log("Submit User Answers", res.data);
    return res.data;
  } catch (err) {
    console.log("error submitting user answers", err);
    return Promise.reject(err);
  }
};

export const fetchVerificationQuestions = async () => {
  try {
    const response = await axios.get("/api/user/questions/");
    console.log("Fetched verification questions", response.data);
    return response.data;
  } catch (err) {
    console.log("Error fetching verification questions", err);
    return Promise.reject(err);
  }
};

export const setUserAddresses = async (payload: UserAddresses, id: string) => {
  try {
    const endPoint = `api/user/${id}/`;
    const res = await axios.patch(endPoint, payload);
    if (!res?.data) throw "Something went wrong while setting address";
    return res;
  } catch (err) {
    console.log("error Setting address", err);
    return Promise.reject(err);
  }
};

export const updateUsername = async (username: string) => {
  try {
    const endPoint = "/api/user/update-username/";
    const res = await axios.patch(endPoint, { username });
    if (!res?.data) throw "Something went wrong while updating the username";
    return res.data;
  } catch (err) {
    console.log("Error updating username", err);
    return Promise.reject(err);
  }
};

interface UserAddresses {
  sui_address: string;
  solana_address: string;
  bitcoin_address: string;
  bitcoin_ordinals_address: string;
}
