export const feeCal = (acquireFee: number, amount: number): number => {
  const adjustedAmount = ((100 - acquireFee) / 100) * amount;
  const adjustedAmountString = adjustedAmount.toFixed(4);

  // Remove trailing zeros if the decimals are zero
  const adjustedAmountWithoutTrailingZeros = adjustedAmountString.replace(
    /(\.[0-9]*[1-9])0+$/,
    "$1"
  );

  // Convert back to number
  const adjustedAmountNumber = Number(adjustedAmountWithoutTrailingZeros);

  return adjustedAmountNumber;
};
