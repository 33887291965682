import {
  Col,
  ConfigProvider,
  Layout,
  Row,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import Banner from "../assets/BannerImage.png";
import Coin from "../assets/Coin Logo.png";
import Text from "../components/Text";
import { ValueRow } from "../components/SinglePageIdoComponent/ProjectOverviewCard";
import eth from "../assets/Svgs/Ethereum.svg";
import glob from "../assets/Svgs/vuesax.svg";
import discoard from "../assets/Svgs/SocialDiscoard.svg";
import twitter from "../assets/Svgs/SocialTwitter.svg";
import telegram from "../assets/Svgs/SocialTelegram.svg";
import linkedin from "../assets/Svgs/SocialLinkedin.svg";
import rediretIcon from "../assets/Svgs/RedirectIcon.svg";
import { useParams } from "react-router-dom";
import { MyTickets } from "../utils/MyTicketsTypes";
import { GetSingleTickets } from "../api/ApiCalls";
import {
  formatNumberWithTwoDecimalPlaces,
  ValuesFormatDecimalAndCommas, ValuesFormatDecimalAndCommasMaxFour,
} from "../Services";
import { formatDate } from "../pages/Home";
import Countdown from "../components/CountDown";
import { PayOutType } from "../utils/MyTicketsTypes";
import { feeCal } from "../Services/FeeCalculation";
import { QuestionCircleOutlined } from "@ant-design/icons";

const SingleTicketPage = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { id } = useParams();

  const [ticket, setTicket] = useState<MyTickets>();

  const fetchSingleTicket = async (id: string) => {
    try {
      const res = await GetSingleTickets(id);
      if (res) {
        //console.log("Single TICKET RESPONSE ", res);
        setTicket(res);
      }
    } catch (err) {
      console.log("Error While fecthing Single Offer ", err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleTicket(id);
    }
  }, []);

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        padding: xs ? "0px 16px" : "0px 30px",
      }}
    >
      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={md ? 12 : 24}>
          <SignleTicket ticket={ticket} />
        </Col>
        <Col span={md ? 12 : 24}>
          <TicketDetails ticket={ticket} />
        </Col>
      </Row>
    </Layout>
  );
};

export default SingleTicketPage;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////CHILDS Components/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const SignleTicket = ({ ticket }: { ticket?: MyTickets }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <Row
      style={{
        width: "100%",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250)",
        overflow: "hidden",
        marginBottom: xs ? "0px": "30px",
      }}
    >
      <Row
        style={{
          height: "360px",
          width: "100%",
          // backgroundImage: `Url(${Banner})`,
          backgroundImage: `url(${ticket?.ido?.project_image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderTopRightRadius: "24px",
          borderTopLeftRadius: "24px",
        }}
      ></Row>
      <Row
        style={{ width: "100%", padding: "24px", backgroundColor: "#120F2D" }}
      >
        <Row style={{ gap: "16px" }}>
          <img
            // src={Coin}
            src={ticket?.ido?.token?.image}
            style={{
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <Text size="sm" style={{ fontSize: "24px", fontWeight: "700" }}>
              {ticket?.ido?.token_name}
            </Text>
            <Text
              size="md"
              style={{ fontWeight: "600", color: "rgba(255, 255, 255, 0.50)" }}
            >
              {`${ticket?.ido?.token?.name} (${ticket?.ido?.token?.symbol})`}
            </Text>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: "24px" }}>
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
              fontWeight: "500",
              lineHeight: "22px",
              width: "100%",
            }}
          >
            {ticket?.ido.project_overview}
          </Text>
        </Row>
        {md ? (
          <IdoDetailSection ticket={ticket} />
        ) : (
          <TicketDetailSection ticket={ticket} />
        )}
      </Row>
    </Row>
  );
};

const TicketDetails = ({ ticket }: { ticket?: MyTickets }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row
      style={{
        padding: "20px",
        gap: "20px",
        backgroundColor: "#120F2D",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250)",
      }}
    >
      {md ? (
        <TicketDetailSection ticket={ticket} />
      ) : (
        <IdoDetailSection ticket={ticket} />
      )}
    </Row>
  );
};

const IdoDetailSection = ({ ticket }: { ticket?: MyTickets }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <Row style={{ width: "100%" }}>
      <Row style={{ marginTop: "24px", gap: "10px", width: "100%" }}>
        <Text size="md" style={{ fontWeight: "600", width: "100%" }}>
          Offer Details
        </Text>
        <Row
          style={{
            padding: "16px",
            borderRadius: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            gap: "10px",
            width: "100%",
          }}
        >
          {ticket?.ido?.price && (
            <ValueRow
              name="Launch Price"
              value={`${ValuesFormatDecimalAndCommasMaxFour(ticket?.ido?.price)} ${
                ticket?.exchange_token_used?.symbol
              }`}
            />
          )}
          <ValueRow
            name="Total Amount"
            value={`${ValuesFormatDecimalAndCommas(
              (
                Number(ticket?.ido?.amount) * Number(ticket?.ido?.price)
              ).toFixed(3)
            )} ${ticket?.exchange_token_used?.symbol}`}
          />
          {ticket?.ido.amount && (
            <ValueRow
              name="Total Issued"
              value={`${ValuesFormatDecimalAndCommas(ticket?.ido?.amount)} ${
                ticket?.ido?.token?.symbol
              }`}
            />
          )}
        </Row>
      </Row>
      <Row style={{ marginTop: "36px", gap: "10px", width: "100%" }}>
        <Text size="md" style={{ fontWeight: "500" }}>
          KEY METRICS
        </Text>
        <Row
          style={{
            padding: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
            borderRadius: "14px",
          }}
        >
          <Col
            span={md ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              borderRight: md ? "1px solid rgba(255, 255, 255, 0.250)" : "none",
              gap: "8px",
              paddingRight: md ? "10px" : "0px",
              paddingBottom: md ? "0px" : "10px",
              marginBottom: md ? "0px" : "10px",
              borderBottom: md ? "none" : "1px solid rgba(255, 255, 255, 0.250)",
            }}
          >
            <ValueRow
              name="Network"
              value={ticket?.ido.network.name ? ticket?.ido?.network?.name : ""}
              icon={ticket?.ido?.network?.network_icon}
            />
            {ticket?.ido?.total_supply && (
              <ValueRow
                name="Total Supply"
                value={`${ValuesFormatDecimalAndCommas(
                  ticket?.ido?.total_supply
                )}`}
              />
            )}
            {ticket?.ido?.valuation && (
              <ValueRow
                name="Project Valuation"
                value={`${ValuesFormatDecimalAndCommas(
                  ticket?.ido?.valuation
                )}`}
              />
            )}
          </Col>
          <Col
            span={md ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingLeft: md ? "10px" : "0px",
            }}
          >
            {ticket?.ido?.initial_market_cap && (
              <ValueRow
                name="Initial Market Cap"
                value={`${ValuesFormatDecimalAndCommas(
                  ticket?.ido?.initial_market_cap
                )}`}
              />
            )}
            {ticket?.ido?.hard_cap && (
              <ValueRow
                name="Allocation Max"
                value={`${ValuesFormatDecimalAndCommas(ticket?.ido.hard_cap)}`}
              />
            )}
            {ticket?.ido?.platform_raise && (
              <ValueRow
                name="Platform Raise"
                value={`${ValuesFormatDecimalAndCommas(
                  ticket?.ido?.platform_raise
                )}`}
              />
            )}
          </Col>
        </Row>
      </Row>
      <Row
        style={{
          marginTop: "24px",
          paddingTop: "16px",
          justifyContent: ticket?.ido.website ? "space-between" : "flex-end",
          alignItems: "center",
          borderTop: "1px solid rgba(255, 255, 255, 0.250)",
          width: "100%",
        }}
      >
        {ticket?.ido?.website && (
          <Col
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => window.open(ticket?.ido?.website, "_blank")}
          >
            <img src={glob} />
            <Text size="sm" style={{ fontWeight: "500" }}>
              Visit Site
            </Text>
          </Col>
        )}
        <Col style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {ticket?.ido?.discord_url && (
            <img
              src={discoard}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(ticket?.ido?.discord_url, "_blank");
              }}
            />
          )}
          {ticket?.ido?.x_url && (
            <img
              src={twitter}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(ticket?.ido?.x_url, "_blank");
              }}
            />
          )}

          {ticket?.ido?.telegram_url && (
            <img
              src={telegram}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(ticket?.ido?.telegram_url, "_blank");
              }}
            />
          )}

          {ticket?.ido?.linked_in_url && (
            <img
              src={linkedin}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(ticket?.ido?.linked_in_url, "_blank");
              }}
            />
          )}
        </Col>
      </Row>
    </Row>
  );
};

const TicketDetailSection = ({ ticket }: { ticket?: MyTickets }) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row style={{ width: "100%", gap: "20px", marginTop: md ? "" : "24px" }}>
      <Text size="md" style={{ fontWeight: "700", width: "100%" }}>
        TICKET DETAILS
      </Text>
      <Row
        style={{
          padding: "16px",
          borderRadius: "16px",
          backgroundColor: "rgba(0, 0, 0, 0.20)",
          width: "100%",
        }}
      >
        <DataRow name="Pre Sale" value={ticket?.ido?.token_name} bottomBorder />
        <DataRow
          name="Total To Receive"
          tooltip="Total amount of tokens you will receive after all payout periods including applied Acquire fee."
          value={` ${feeCal(
            Number(ticket?.ido.acquire_fee),
            Number(ticket?.amount_received)
          )} ${ticket?.ido.token.symbol}`}
          bottomBorder
        />
        <DataRow
          name="Total Sent"
          value={`${ticket?.amount_paid} ${ticket?.exchange_token_used?.symbol}`}
          bottomBorder
        />
        <DataRow
          name="TX Hash Sent"
          bottomBorder
          value={ticket?.transaction_hash}
          iconRedirect
          colorvalue="#909090"
        />
        <DataRow
          name="Status"
          node={
            <StatusNode status={ticket?.ido?.stage ? ticket?.ido?.stage : ""} />
          }
          bottomBorder
        />
        <DataRow
          name="Date"
          value={formatDate(
            ticket?.ido.allocation_start ? ticket?.ido.allocation_start : ""
          )}
          colorvalue="rgba(255, 255, 255, 0.50)"
        />
      </Row>
      <Row style={{ width: "100%" }}>
        <Text
          size="sm"
          style={{
            color: "rgba(255, 255, 255, 0.50)",
            width: "100%",
            marginBottom: "8px",
          }}
        >
          Payout overview
        </Text>
        <Row
          style={{
            padding: "16px",
            borderRadius: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
          }}
        >
          <DataRow
            name="Payout Overview"
            node={
              // <PayOutOverviewNode
              //   total={
              //     ticket?.total_allocation_parts
              //       ? ticket?.total_allocation_parts
              //       : 0
              //   }
              //   paid={
              //     ticket?.paid_allocation_parts
              //       ? ticket.paid_allocation_parts
              //       : 0
              //   }
              // />
              <Text size="sm">
                {feeCal(
                  Number(ticket?.ido.acquire_fee),
                  Number(ticket?.amount_sent)
                )}{" "}
                /{" "}
                {feeCal(
                  Number(ticket?.ido.acquire_fee),
                  Number(ticket?.amount_received)
                )}{" "}
                {ticket?.ido.token.symbol}
              </Text>
            }
            bottomBorder
          />
          <DataRow
            name="Acquire Fee"
            value={`${ticket?.ido?.acquire_fee} %`}
            bottomBorder
            colorvalue="rgba(255, 255, 255, 0.50)"
          />
          <DataRow
            name="Next Payout"
            node={
              <NextPayoutNode
                payout={ticket?.next_payout ? ticket.next_payout : undefined}
              />
            }
            bottomBorder
          />
        </Row>
      </Row>
      <Row style={{ width: "100%" }}>
        <Text
          size="sm"
          style={{
            color: "rgba(255, 255, 255, 0.50)",
            width: "100%",
            marginBottom: "8px",
          }}
        >
          Recent payout
        </Text>
        <Row
          style={{
            padding: "16px",
            borderRadius: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
          }}
        >
          <RecentPayoutTable
            data={ticket?.recent_payouts ? ticket?.recent_payouts : []}
            symbol={ticket?.ido?.token?.symbol}
            fee={ticket ? ticket.ido.acquire_fee : ""}
            ticket={ticket}
          />
        </Row>
      </Row>
    </Row>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////SUB CHILDS////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
interface DataRowProp {
  topBorder?: boolean;
  bottomBorder?: boolean;
  name: string;
  value?: string;
  node?: React.ReactNode;
  colorvalue?: string;
  iconRedirect?: boolean;
  tooltip?: string;
}

const DataRow = (props: DataRowProp) => {
  const {
    name,
    bottomBorder = false,
    node,
    topBorder = false,
    value,
    colorvalue,
    iconRedirect = false,
    tooltip,
  } = props;

  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "14px 0px",
        borderTop: topBorder ? "1px solid rgba(255, 255, 255, 0.05)" : "",
        borderBottom: bottomBorder ? "1px solid rgba(255, 255, 255, 0.05)" : "",
      }}
    >
      <Row style={{ alignItems: "center", gap: "5px" }}>
        <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
          {name}
        </Text>
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined
              style={{ color: "rgba(255, 255, 255, 0.50)", cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </Row>

      {value && (
        <Col style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Text size="md" style={{ color: colorvalue ? colorvalue : "White" }}>
            {name === "TX Hash Sent" ? shortenString(value) : value}
          </Text>
          {iconRedirect && value !== "Not Known" && (
            <img
              style={{ cursor: "pointer" }}
              src={rediretIcon}
              onClick={() => {
                if (iconRedirect) {
                  const url = `https://etherscan.io/tx/${value}`;
                  window.open(url, "_blank");
                }
              }}
            />
          )}
        </Col>
      )}
      {node && node}
    </Row>
  );
};

const StatusNode = ({ status }: { status: string }) => {
  return (
    <Col
      style={{
        borderRadius: "8px",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        padding: "7px 30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        size="xs"
        style={{ color: status === "OPEN" ? "#33FFFF" : "White" }}
      >
        {status}
      </Text>
    </Col>
  );
};

const NextPayoutNode = ({ payout }: { payout: string | undefined }) => {
  return (
    <>
      {" "}
      {payout !== undefined && (
        <Col
          style={{
            padding: "7px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderRadius: "8px",
          }}
        >
          <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.80)" }}>
            {payout !== undefined && (
              <Countdown endDateString={payout}></Countdown>
            )}
          </Text>
        </Col>
      )}
    </>
  );
};

const PayOutOverviewNode = ({
  total,
  paid,
}: {
  total: number;
  paid: number;
}) => {
  return (
    <Col
      style={{
        display: "flex",
        alignItems: "center",
        gap: "2px",
        flexWrap: "nowrap",
      }}
    >
      {Array.from({ length: total }, (_, index) => (
        <TotalPayoutBars key={index} active={index + 1 <= paid} />
      ))}
    </Col>
  );
};

const TotalPayoutBars = ({ active }: { active: boolean }) => {
  const { sm } = useBreakpoint();
  return (
    <div
      style={{
        height: "32px",
        width: sm ? "16px" : "10px",
        borderRadius: "4px",
        backgroundColor: active ? "#33FFFF" : "rgba(255, 255, 255, 0.252)",
      }}
    ></div>
  );
};

interface RecentPayoutTableProp {
  data: PayOutType[];
  symbol?: string;
  fee: string;
  ticket?: MyTickets;
}
const RecentPayoutTable = (prop: RecentPayoutTableProp) => {
  const { data, symbol, fee, ticket } = prop;

  const columns: TableColumnsType<PayOutType> = [
    {
      title: "PAYOUT AMOUNT",
      dataIndex: "amount",
      render: (val, record) => {
        return (
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
            }}
          >
            {feeCal(Number(fee), Number(val))} {symbol}
          </Text>
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "updated_at",
      responsive: ["sm"],
      render: (val) => {
        return (
          <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
            {formatDate(val)}
          </Text>
        );
      },
    },
    {
      title: "TX HASH",
      dataIndex: "fulfilled_transaction_hash",
      render: (val) => {
        return (
          <Col style={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
              {shortenString(val)}
            </Text>
            <img
              src={rediretIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                const explorerUrl = ticket?.ido?.token?.network?.explorer_url;
                if (explorerUrl && val) {
                  window.open(`${explorerUrl}${val}`, "_blank");
                }
              }}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Row style={{ width: "100%" }}>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "transparent",
              borderColor: "transparent",
              headerBorderRadius: 0,
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={data.map((item) => ({ ...item, key: item.id }))}
          size="small"
          style={{ width: "100%" }}
          pagination={false}
          className="ticket-detail-table"
        />
      </ConfigProvider>
    </Row>
  );
};

function shortenString(str: string, length = 3) {
  if (str.length <= length * 2 + 3) {
    return str;
  }
  const start = str.substring(0, length);
  const end = str.substring(str.length - length);
  return `${start}...${end}`;
}
