export function formatNumberWithTwoDecimalPlaces(input: string | undefined) {
  if (input === undefined) {
    return;
  }
  const parsedNumber = parseFloat(input);
  if (isNaN(parsedNumber)) {
    return "Invalid number";
  }
  return parsedNumber.toFixed(2);
}

export function ValuesFormatDecimalAndCommas(input: string | undefined) {
  if (input === undefined) {
    return;
  }
  let number = parseFloat(input);
  if (isNaN(number)) {
    return "Invalid input";
  }
  let [integerPart, decimalPart] = number.toFixed(3).split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parseInt(decimalPart) === 0) {
    return integerPart;
  } else {
    return `${integerPart}.${decimalPart}`;
  }
}

export function ValuesFormatDecimalAndCommasMaxFour(input: string | undefined) {
  if (input === undefined) {
    return;
  }
  let number = parseFloat(input);
  if (isNaN(number)) {
    return "Invalid input";
  }
  let [integerPart, decimalPart] = number.toFixed(4).split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parseInt(decimalPart) === 0) {
    return integerPart;
  } else {
    return `${integerPart}.${decimalPart}`;
  }
}
