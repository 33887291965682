import React, { useEffect, useState } from "react";
import Layout from "antd/es/layout/layout";
import Countdown from "../components/CountDown";
import MyAddresses from "../components/MyAddress/MyAddresses";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  TableProps,
  Tooltip,
  message,
} from "antd";
import Hero from "../components/Hero";
import { UserOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Text from "../components/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {
  GetSumsubAccessToken,
  setUserEmail,
  GetMyTickets,
  fetchVerificationQuestions,
  submitUserAnswers,
  updateUsername,
} from "../api/ApiCalls";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useSelector } from "react-redux";
import { userState } from "../redux/reducers";
import redirectIcon from "../assets/Svgs/RedirectIcon.svg";
import Nodata from "../assets/Svgs/NoDataTable.svg";
import goldenTick from "../assets/GoldenTick.svg";

import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MyTickets } from "../utils/MyTicketsTypes";
import TextArea from "antd/es/input/TextArea";
import { feeCal } from "../Services/FeeCalculation";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useAccount } from "wagmi";

const MyAccount = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { user } = useSelector(userState);
  const navigate = useNavigate();
  const { address } = useAccount();

  useEffect(() => {
    if (!user || !address) {
      navigate("/");
    }
  }, [user, address, navigate]);

  if (!user || !address) {
    return null;
  }
  
  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        padding: xs ? "0px 16px" : "0px 30px",
      }}
    >
      <Row style={{ margin: "24px 0px", width: "100%" }}>
        <Hero
          title="My Acquire Account"
          title2=" "
          subtitle="Your personal overview of your account, addresses and tickets."
          buttonText="My Account"
          icon={<UserOutlined />}
        />
      </Row>
      <Row
        style={{
          marginTop: "0px",
          padding: "16px",
          borderRadius: "24px",
          backgroundColor: "#120F2D",
          border: "1px solid rgba(255, 255, 255, 0.250)",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Text size="md" style={{ fontWeight: "700" }}>
          Verify Account
        </Text>
        <Row
          style={{
            gap: "16px",
            flexDirection: xs ? "column" : "row",
            flexWrap: "nowrap",
          }}
        >
          <Col style={{ flex: xs ? "unset" : 1 }}>
            <EmailVerificationSegment />
          </Col>
          <Col style={{ flex: xs ? "unset" : 1 }}>
            <KYCVerificationSegment />
          </Col>
          <Col style={{ flex: xs ? "unset" : 1 }}>
            <AdditionalInformationSegment />
          </Col>
        </Row>
      </Row>

      <Row
        style={{
          marginTop: "24px",
          padding: "16px",
          borderRadius: "24px",
          backgroundColor: "#120F2D",
          border: "1px solid rgba(255, 255, 255, 0.250)",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Text size="md" style={{ fontWeight: "700" }}>
          My Connection
        </Text>
        <Row
          style={{
            gap: "16px",
            flexDirection: xs ? "row" : "row",
            flexWrap: "nowrap",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              size="xs"
              style={{
                fontWeight: "600",
                opacity: "0.5",
                marginTop: "1px",
                marginBottom: "8px",
              }}
            >
              Currently connected with:
            </Text>
            <Text size="md" style={{ fontWeight: "700" }}>
              {user?.wallet_address}
            </Text>
          </div>
        </Row>
      </Row>

      <MyAddresses />
      <Row
        style={{
          marginTop: "24px",
          padding: md ? "16px" : " 16px 5px",
          backgroundColor: "#120f2d",
          borderRadius: "24px",
          border: "1px solid rgba(255, 255, 255, 0.250)",
        }}
      >
        <Text
          size="md"
          style={{
            fontWeight: "600",
            marginBottom: "16px",
            fontFamily: "Inter",
            width: "100%",
            marginLeft: sm || xs ? "10px" : "0px",
          }}
        >
          My Tickets
        </Text>
        <MyTicketsTable />
      </Row>
      <Row
        style={{
          height: "30px",
          minHeight: "30px",
          width: "100%",
          display: "flex",
        }}
      ></Row>
    </Layout>
  );
};

export default MyAccount;

const AdditionalInformationSegment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isUsernameSubmitted, setIsUsernameSubmitted] = useState(false);
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [form] = Form.useForm();
  const [otherAnswers, setOtherAnswers] = useState<any>({});
  const { user } = useSelector(userState);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.resetFields();
    setSubmitted(false);
    setOtherAnswers({});
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setSubmitted(false);
    setOtherAnswers({});
    setIsModalOpen(false);
  };

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const fetchedQuestions = await fetchVerificationQuestions();
        setQuestions(fetchedQuestions);
      } catch (error) {
        console.error("Error loading questions:", error);
      }
    };

    loadQuestions();
  }, []);

  const handleFormFinish = async (values: any) => {
    try {
      const userId = user.id;

      if (values.username.length < 6) {
        message.error("Username must be longer than 6 characters.");
        return;
      }

      const updateUsernameRes = await updateUsername(values.username);

      const formattedAnswers = questions.map((question: any) => ({
        question_id: question.id,
        selected_answer_id:
          question.question_type === "MULTIPLE"
            ? values[`question_${question.id}`]
            : null,
        other_answer:
          question.question_type === "SINGLE"
            ? values[`question_${question.id}`]
            : otherAnswers[question.id] || null,
      }));

      await submitUserAnswers(userId, formattedAnswers);
      setSubmitted(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      if (err instanceof Error) {
        if ((err as any)?.response.data.username) {
          message.error((err as any)?.response.data.username);
        } else {
          message.error(
            "There was an error submitting your answers, try again later."
          );
        }
      }
    }
  };

  const handleSelectChange = (value: any, questionId: any) => {
    form.setFieldsValue({ [`question_${questionId}`]: value });
    if (value === "Other") {
      setOtherAnswers((prev: any) => ({ ...prev, [questionId]: "" }));
    } else {
      const newOtherAnswers = { ...otherAnswers };
      delete newOtherAnswers[questionId];
      setOtherAnswers(newOtherAnswers);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBg: "#0C0A1F",
              activeBorderColor: "#424242",
              hoverBg: "#0C0A1F",
              hoverBorderColor: "#424242",
              colorBgContainer: "#0C0A1F",
              colorBorder: "#424242",
              colorIcon: "#424242",
              colorText: "#FFF",
              colorTextPlaceholder: "#424242",
              addonBg: "rgba(255, 255, 255, 0.04)",
            },
            Checkbox: {
              colorPrimary: "#33FFFF",
              colorPrimaryHover: "#33FFFF",
              colorText: "#FFF",
              colorBgContainer: "#0C0A1F",
              colorBorder: "#424242",
            },
          },
        }}
      >
        <Row
          style={{
            padding: "16px",
            borderRadius: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            gap: "24px",
            opacity: user?.email_verified && user?.kyc_complete ? "1" : "0.4",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
              Additional information
            </Text>
            <CheckCircleOutlined
              style={{
                color: user.questions_verified
                  ? "#52c41a"
                  : "rgba(255, 255, 255, 0.50)",
              }}
            />
          </Row>
          <Row style={{ justifyContent: "center", width: "100%" }}>
            <Text
              size="md"
              style={{
                textAlign: "center",
                color: "rgba(255, 255, 255, 0.50)",
              }}
            >
              {!user?.email_verified
                ? "Please first verify your email"
                : user?.questions_answered
                ? "Info added successfully"
                : "Add additional info"}
            </Text>
          </Row>
          {user?.questions_verified ? (
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled
              onClick={showModal}
            >
              Verified
            </Button>
          ) : (
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!user?.email_verified || !user?.kyc_complete}
              onClick={showModal}
            >
              Add information
            </Button>
          )}
        </Row>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          footer={false}
          closable={false}
          width={xs ? 363 : 530}
          styles={{
            content: {
              backgroundColor: "#120F2D",
              border: "1px solid rgba(255, 255, 255, 0.250)",
              borderRadius: "24px",
              padding: xs ? "24px 16px" : "36px",
              maxHeight: "80vh",
              overflow: "auto",
            },
          }}
        >
          {!submitted ? (
            <>
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Text
                  size="sm"
                  style={{ fontSize: "20px", marginBottom: "8px" }}
                >
                  Additional information
                </Text>
                <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
                  Almost there! Please provide these last few details.
                </Text>
              </Row>

              <Form
                name="Form"
                form={form}
                style={{ width: "100%", marginTop: "16px" }}
                onFinish={handleFormFinish}
              >
                <Row style={{ flexDirection: "column", gap: "8px" }}>
                  <Text size="xs" style={{ color: "#909090" }}>
                    Username <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: "Please enter your username" },
                    ]}
                  >
                    <Input
                      className="username-field"
                      style={{ borderRadius: "6px", textAlign: "left" }}
                      placeholder="Enter your username"
                    />
                  </Form.Item>
                </Row>

                {questions.map((question: any) => (
                  <Row
                    key={question.id}
                    style={{ flexDirection: "column", gap: "8px" }}
                  >
                    <Text size="xs" style={{ color: "#909090" }}>
                      {question.question_text}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Form.Item
                      name={`question_${question.id}`}
                      rules={[
                        {
                          required: true,
                          message: "Please answer this question",
                        },
                      ]}
                    >
                      {question.question_type === "MULTIPLE" ? (
                        <Select
                          onChange={(value) =>
                            handleSelectChange(value, question.id)
                          }
                          placeholder="Select an option"
                        >
                          {question.answers.map((answer: any) => (
                            <Select.Option key={answer.id} value={answer.id}>
                              {answer.answer_text}
                            </Select.Option>
                          ))}
                          <Select.Option value="Other">
                            Other (Specify)
                          </Select.Option>
                        </Select>
                      ) : (
                        <Input
                          className="username-field"
                          placeholder={
                            question.placeholder || "Enter your answer"
                          }
                          style={{ borderRadius: "6px", textAlign: "left" }}
                        />
                      )}
                    </Form.Item>
                    {question.question_type === "MULTIPLE" &&
                      otherAnswers[question.id] !== undefined && (
                        <Form.Item
                          name={`other_${question.id}`}
                          rules={[
                            {
                              required: true,
                              message: "Please specify your answer",
                            },
                          ]}
                        >
                          <TextArea
                            rows={4}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              setOtherAnswers((prev: any) => ({
                                ...prev,
                                [question.id]: e.target.value,
                              }))
                            }
                          />
                        </Form.Item>
                      )}
                  </Row>
                ))}
                <Row style={{ width: "100%" }}>
                  <Form.Item
                    name="check"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox required />
                    <Text
                      size="sm"
                      style={{
                        color: "rgba(255, 255, 255, 0.50)",
                        marginLeft: "5px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      My source of funds does not involve "Weapons, armament
                      manufacturers; Regulated money transfer agents;
                      Non-professional regulated foreign exchange dealers;
                      Charities, religious, political and other non- profit
                      organizations; Commodity trading; Diamond, precious stones
                      and metal traders, jewelry dealers; Adult Entertainment
                      industry; Arts and antique dealing; Casino, lottery and
                      other gambling industry
                    </Text>
                  </Form.Item>
                  <Form.Item
                    name="check"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox required />
                    <Text
                      size="sm"
                      style={{
                        color: "rgba(255, 255, 255, 0.50)",
                        marginLeft: "5px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      I am the ultimate beneficial owner of the received utility
                      tokens.
                    </Text>
                  </Form.Item>
                  <Form.Item
                    name="check"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox required />
                    <Text
                      size="sm"
                      style={{
                        color: "rgba(255, 255, 255, 0.50)",
                        marginLeft: "5px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      I have read the Acquire.Fi privacy policy and terms of
                      service and agree to them.
                    </Text>
                  </Form.Item>

                  <Form.Item
                    name="check"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox required />
                    <Text
                      size="sm"
                      style={{
                        color: "rgba(255, 255, 255, 0.50)",
                        marginLeft: "5px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      For estimated average participation levels greater than
                      $20,000 USD please attach a CV, Tax Return and bank
                      statement in an email to{" "}
                      <a href="mailto:team@acquire.fi">team@acquire.fi</a>
                    </Text>
                  </Form.Item>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Form.Item style={{ width: "100%" }}>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      size="large"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </>
          ) : (
            <Row
              style={{
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={goldenTick} />
              {/* <Text size="sm" style={{ fontSize: "18px" }}>
                Entries Submitted!
              </Text> */}
            </Row>
          )}
        </Modal>
      </ConfigProvider>
    </>
  );
};

const EmailVerificationSegment = () => {
  const { user } = useSelector(userState);
  const [email, setEmail] = useState(user?.email || "");

  const handleEmailVerification = async () => {
    if (!email.trim()) {
      message.error("Please enter an email.");
      return;
    }

    try {
      const response = await setUserEmail(email);
      if (response.success) {
        message.success(response.success);
      } else if (response.message) {
        message.error(response.message);
      }
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  return (
    <Row
      style={{
        padding: "16px",
        borderRadius: "16px",
        backgroundColor: "rgba(0, 0, 0, 0.20)",
        gap: "24px",
        opacity: "1",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
          Email
        </Text>
        <CheckCircleOutlined
          style={{
            color: user?.email_verified
              ? "#52c41a"
              : "rgba(255, 255, 255, 0.50)",
          }}
        />
      </Row>
      <Row style={{ justifyContent: "center", width: "100%" }}>
        <Input
          placeholder="enter e-mail..."
          style={{
            width: "100%",
            color: "rgba(255, 255, 255, 0.50)",
            textAlign: "center",
            backgroundColor: "transparent",
            borderColor: "rgba(255, 255, 255, 0.255)",
          }}
          disabled={user?.email_verified}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Row>
      <Button
        style={{ width: "100%" }}
        type="primary"
        disabled={user?.email_verified}
        onClick={handleEmailVerification}
      >
        {user?.email_verified ? "Verified" : "Verify"}
      </Button>
    </Row>
  );
};

const KYCVerificationSegment = () => {
  const { user } = useSelector(userState);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const kycStatusMessages: any = {
    NR: "Hey! Let's do a quick KYC Verification",
    RV: "Your KYC verification is currently in review.",
    AP: "Congratulations, your KYC verification is approved!",
    RJ: "You failed the KYC check, please contact us to appeal.",
    OH: "Your KYC verification is on hold. Please check back later.",
  };

  const handleVerifyClick = async () => {
    setIsModalVisible(true);
    try {
      const newToken = await GetSumsubAccessToken();
      setAccessToken(newToken);
    } catch (error) {
      console.error("Failed to get access token:", error);
      message.error("Failed to initiate KYC verification. Please try again.");
      setIsModalVisible(false);
    }
  };

  const accessTokenExpirationHandler = async () => {
    try {
      const newToken = await GetSumsubAccessToken();
      setAccessToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      message.error(
        "Session expired. Please start the KYC verification again."
      );
      return "";
    }
  };

  const getButtonMessage = () => {
    switch (user?.kyc_status) {
      case "NR":
        return "Let's Verify";
      case "RJ":
        return "Rejected";
      case "AP":
        return "Verified";
      default:
        return "Verification In Progress";
    }
  };

  return (
    <Row
      style={{
        padding: "16px",
        borderRadius: "16px",
        backgroundColor: "rgba(0, 0, 0, 0.20)",
        gap: "24px",
        opacity: user?.email_verified ? "1" : "0.4",
        flexGrow: 1,
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
          KYC Verification
        </Text>
        <CheckCircleOutlined
          style={{
            color:
              user?.kyc_complete && user?.kyc_status === "AP"
                ? "#52c41a"
                : "rgba(255, 255, 255, 0.50)",
          }}
        />
      </Row>

      <Row style={{ justifyContent: "center", width: "100%" }}>
        <Text
          size="md"
          style={{ color: "rgba(255, 255, 255, 0.50)", textAlign: "center" }}
        >
          {kycStatusMessages[user?.kyc_status]}
        </Text>
      </Row>

      <Row style={{ justifyContent: "center", width: "100%" }}>
        <Button
          style={{ width: "100%", marginTop: "16px" }}
          type="primary"
          onClick={handleVerifyClick}
          disabled={
            !user?.email_verified ||
            user?.kyc_status === "AP" ||
            user?.kyc_status === "RJ"
          }
        >
          {getButtonMessage()}
        </Button>
      </Row>
      <Modal
        title="KYC Verification"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="80%"
      >
        {accessToken && (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={accessTokenExpirationHandler}
            config={{
              lang: "en",
              email: user?.email,
              phone: "",
              theme: "light",
            }}
            options={{
              addViewportTag: false,
              adaptIframeHeight: true,
            }}
            onMessage={(message) => console.log("WebSDK Message:", message)}
            onError={(error) => console.error("WebSDK Error:", error)}
          />
        )}
      </Modal>
    </Row>
  );
};

const MyTicketsTable = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const navigate = useNavigate();
  const [myTickets, setMyTickets] = useState<MyTickets[]>();

  const fecthMyTickets = async () => {
    try {
      const res = await GetMyTickets();
      if (res) {
        console.log("Fetch My Tickets API response ", res);
        setMyTickets(res);
      }
    } catch (error) {
      console.log("Error While Fectch My Tickets ", error);
    }
  };

  useEffect(() => {
    fecthMyTickets();
  }, []);

  const columns: TableProps<MyTickets>["columns"] = [
    {
      title: "PRE SALE",
      dataIndex: "preSale",
      key: "preSale",
      render: (TextVal, record) => {
        return (
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
            }}
          >
            {record.ido.token_name}
          </Text>
        );
      },
    },
    {
      title: (
        <Row
          style={{
            gap: "5px",
            alignItems: "center",
            justifyContent: xs ? "end" : "",
          }}
        >
          TOTAL TO RECEIVE
          <Tooltip title="Total amount of tokens you will receive after all payout periods including applied Acquire fee.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "toReceive",
      key: "toReceive",
      align: sm ? "start" : "end",

      render: (TextVal, record) => {
        return (
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
            }}
          >
            {feeCal(
              Number(record.ido.acquire_fee),
              Number(record.amount_received)
            )}{" "}
            {record.ido.token.symbol}
          </Text>
        );
      },
    },
    {
      title: "TOTAL SENT",
      dataIndex: "sent",
      key: "sent",
      responsive: ["sm"],
      render: (TextVal, record) => {
        return (
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
            }}
          >
            {record.amount_paid} {record?.exchange_token_used?.symbol}
          </Text>
        );
      },
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          NEXT PAYOUT
          <Tooltip title="Your next payout will appear when the offer has been ended.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "nextpayout",
      key: "nextpayout",
      responsive: ["sm"],
      render: (TextVal, record) => {
        return <NextPayout str={record.next_payout} />;
      },
    },
    {
      title: (
        <Row style={{ gap: "5px", alignItems: "center" }}>
          TOTAL PAYOUT
          <Tooltip title="Amount of tokens you already have received.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Row>
      ),
      dataIndex: "totalpayout",
      key: "totalpayout",
      responsive: ["sm"],
      render: (TextVal, record) => {
        return (
          <Text size="sm">
            {feeCal(Number(record.ido.acquire_fee), Number(record.amount_sent))}{" "}
            /{" "}
            {feeCal(
              Number(record.ido.acquire_fee),
              Number(record.amount_received)
            )}{" "}
            {record.ido.token.symbol}
          </Text>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      responsive: ["sm"],
      render: (TextVal, record) => {
        return (
          <div className="mytickets-tabel-status">
            <Text
              size="xs"
              style={{
                color:
                  TextVal === "OPEN" ? "#33FFFF" : "rgba(230, 244, 255, 0.50)",
              }}
            >
              {record.ido.stage}
            </Text>
          </div>
        );
      },
    },
  ];

  const handleRowClick = (record: MyTickets) => {
    console.log("Clicked record:", record);
    navigate(`/single-ticket/${record.id}`);
  };

  return (
    <Row style={{ width: "100%", overflowX: "auto" }}>
      <Table
        size="small"
        columns={columns}
        dataSource={myTickets?.map((item, index) => ({
          ...item,
          key: index,
        }))}
        // dataSource={[]}
        onRow={(record, rowIndex) => {
          if (!sm) {
            return {};
          } else {
            return {
              onClick: () => handleRowClick(record),
            };
          }
        }}
        style={{
          width: "100%",
          minWidth: sm ? "700px" : "",
          cursor: "pointer",
        }}
        pagination={{ pageSize: 5 }}
        expandable={
          !sm
            ? {
                expandedRowRender: (record) => (
                  <div
                    style={{
                      padding: "0px",
                      marginRight: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      paddingRight: "45px",
                    }}
                  >
                    <ExpandedRow
                      title="SENT"
                      value={`${record.amount_paid} ${record?.exchange_token_used?.symbol}`}
                    />
                    <RowNextPayout
                      title="NEXT PAYOUT"
                      str={record.next_payout}
                    />
                    <Row
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        size="sm"
                        style={{
                          fontSize: "13px",
                          color: "rgba(255, 255, 255, 0.50)",
                        }}
                      >
                        TOTAL PAYOUT
                      </Text>
                      <Text size="sm">
                        {feeCal(
                          Number(record.ido.acquire_fee),
                          Number(record.amount_sent)
                        )}{" "}
                        /{" "}
                        {feeCal(
                          Number(record.ido.acquire_fee),
                          Number(record.amount_received)
                        )}{" "}
                        {record.ido.token.symbol}
                      </Text>
                    </Row>
                    <ExpandedSatusRow title="Status" value={record.ido.stage} />

                    <Button
                      type="primary"
                      onClick={() => {
                        handleRowClick(record);
                      }}
                    >
                      View Ticket
                    </Button>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <UpOutlined
                      onClick={(e: any) => onExpand(record, e)}
                      style={{ color: "#33FFFF" }}
                    />
                  ) : (
                    <DownOutlined
                      onClick={(e: any) => onExpand(record, e)}
                      style={{ color: "#909090" }}
                    />
                  ),
                expandIconColumnIndex: 3,
              }
            : undefined
        }
        locale={{
          emptyText: (
            <div
              style={{
                textAlign: "center",
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <img src={Nodata} />
              <Text
                size="md"
                style={{
                  fontSize: "18px",
                  color: "#7C7C82",
                }}
              >
                Your ticket purchases will appear here
              </Text>
            </div>
          ),
        }}
      />
    </Row>
  );
};

const RowNextPayout = ({ title, str }: { title: string; str: string }) => {
  return (
    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
      <Text
        size="sm"
        style={{ fontSize: "13px", color: "rgba(255, 255, 255, 0.50)" }}
      >
        {title}
      </Text>
      <NextPayout str={str} />
    </Row>
  );
};

interface ExpandedRowProps {
  title: string;
  value: string;
  icon?: boolean;
}
const ExpandedRow = (props: ExpandedRowProps) => {
  const { icon, title, value } = props;
  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text
        size="sm"
        style={{ fontSize: "13px", color: "rgba(255, 255, 255, 0.50)" }}
      >
        {title}
      </Text>
      <Col style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <Text size="sm">{icon ? shortenString(value) : value}</Text>
        {icon && <img src={redirectIcon} />}
      </Col>
    </Row>
  );
};

interface ExpandedSatusRowProps {
  title: string;
  value: string;
}
const ExpandedSatusRow = (props: ExpandedSatusRowProps) => {
  const { title, value } = props;
  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text
        size="sm"
        style={{ fontSize: "13px", color: "rgba(255, 255, 255, 0.50)" }}
      >
        {title}
      </Text>
      <Col
        style={{
          display: "flex",
          padding: "7px 60px",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
      >
        <Text
          size="sm"
          style={{
            color: value === "OPEN" ? "#33FFFF" : "rgba(255, 255, 255, 0.50)",
          }}
        >
          {value}
        </Text>
      </Col>
    </Row>
  );
};

const NextPayout = ({ str }: { str: string }) => {
  return (
    <>
      {str !== null && (
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "7px 8px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            borderRadius: "8px",
            maxWidth: "170px",
          }}
        >
          <Text size="xs">
            <Countdown endDateString={str}></Countdown>
          </Text>
        </Col>
      )}
    </>
  );
};

const TotalPayoutBars = ({ active }: { active: boolean }) => {
  return (
    <div
      style={{
        height: "32px",
        width: "10px",
        borderRadius: "4px",
        backgroundColor: active ? "#33FFFF" : "rgba(255, 255, 255, 0.252)",
      }}
    ></div>
  );
};

const TotalPayout = ({ paid, total }: { paid: number; total: number }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "2px",
        flexWrap: "nowrap",
      }}
    >
      {Array.from({ length: total }, (_, index) => (
        <TotalPayoutBars key={index} active={index + 1 <= paid} />
      ))}
    </div>
  );
};

export function shortenString(str: string, length = 3) {
  if (str.length <= length * 2 + 3) {
    return str;
  }
  const start = str.substring(0, length);
  const end = str.substring(str.length - length);
  return `${start}...${end}`;
}
