import { Layout } from "antd";
import React from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const OTC = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        padding: xs ? "0px 16px" : "0px 30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          fontSize: xs ? "20px" : "40px",
          fontWeight: "600",
          color: "#33FFFF",
        }}
      >
        coming soon
      </h1>
    </Layout>
  );
};

export default OTC;
