import Layout from "antd/es/layout/layout";
import React, { useEffect } from "react";
import Header from "../components/Header";
import { Button, Col, Row } from "antd";
import { useAccount } from "wagmi";
import { GetIdo } from "../api/ApiCalls";
import { idoState, setido } from "../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import IdoCards from "../components/IdoCards";
import cardRed from "../assets/CardRed.png";
import cardGreen from "../assets/CardGreen.png";
import cardBrown from "../assets/CardBrown.png";
import CoinRed from "../assets/Svgs/CoinRed.svg";
import CoinGreen from "../assets/Svgs/CoinGreen.svg";
import CoinBrown from "../assets/Svgs/CoinBrown.svg";
import Hero from "../components/Hero";
import { OrdersHistoryTabel } from "./idoDetail/IdoDetail";
import { IconOfferButton } from "../components/Icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const Home = () => {
  const { address } = useAccount();
  const dispatch = useDispatch();
  const idoList = useSelector(idoState);
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const fetchIdo = async () => {
    try {
      const res = await GetIdo();
      if (res) {
        dispatch(setido(res));
      }
    } catch (err) {
      console.log("Error While Fetch Ido ", err);
    }
  };

  useEffect(() => {
    fetchIdo();
  }, []);

  const getRandomIndex = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  const coverImages = [cardRed, cardGreen, cardBrown];
  const iconImages = [CoinRed, CoinGreen, CoinBrown];

  return (
    <Layout
      style={{
        backgroundColor: "#0C0A1F",
        padding: xs ? "0px 16px" : "0px 30px",
      }}
    >
      <Row style={{ margin: "24px 0px", width: "100%" }}>
        <Hero
          title="Acquire.Fi"
          title2="Offers"
          subtitle="Institutional offers made available to retail"
          buttonText="Pre-market"
          icon={<IconOfferButton />}
        />
      </Row>
      <Row style={{ display: "flex", flexWrap: "wrap", paddingBottom:"30px" }} gutter={[24, 24]}>
        {idoList.map((ido, index) => (
          <Col style={{}} key={index} className="cardoverview">
            <IdoCards
              TokenName={ido.token_name}
              category={ido.category}
              coverImg={
                ido.project_image
                  ? ido.project_image
                  : coverImages[getRandomIndex(3)]
              }
              iconImg={
                ido?.token.image
                  ? ido?.token.image
                  : iconImages[getRandomIndex(3)]
              }
              raiseTotal={ido.amount}
              startDate={formatDate(
                ido.stage === "interest"
                  ? ido.interest_start
                  : ido.stage === "allocation"
                  ? ido.allocation_start
                  : ido.fcfs_start
              )}
              endDate={formatDate(
                ido.stage === "interest"
                  ? ido.interest_end
                  : ido.stage === "allocation"
                  ? ido.allocation_end
                  : ido.fcfs_end
              )}
              status={ido.open}
              key={index}
              id={ido.id}
              stage={ido.stage}
              tokenSymbol={ido.token.symbol}
              ido={ido}
            />
          </Col>
        ))}
      </Row>
    </Layout>
  );
};

export default Home;

export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const meridiem = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight
  const formattedTime = `${String(hours).padStart(
    2,
    "0"
  )}:${minutes} ${meridiem}`;
  return `${day}/${month}/${year} ${formattedTime}`;
}
