import React, { useState, useEffect, useRef } from 'react';
import { message, Spin } from 'antd';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyUserEmail } from '../api/ApiCalls';

const VerifyUser = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const verificationStarted = useRef(false);

  useEffect(() => {
    if (verificationStarted.current) {
      return; 
    }
    verificationStarted.current = true;

    const verifyUser= async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        message.error('Token is missing in the URL.');
        setTimeout(() => navigate('/account'), 3500);
        setLoading(false);
        return;
      }

      try {
        const response = await verifyUserEmail(token);
        if (response.verified) {
          message.success('Your email has been successfully verified!');
        } else if (response.used) {
          message.error('This link has already been used.');
        }
      } catch (err) {
        message.error('An error occurred during email verification.');
      } finally {
        setTimeout(() => navigate('/account'), 3500);
        setLoading(false);
      }
    };

    verifyUser();
  }, [navigate, location.search]);



  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <Spin size="large" tip="Validating email..." />
      </div>
    );
  }
  return null;
};

export default VerifyUser;