export async function determineStages(
  interestStartTime: string,
  allocationStartTime: string,
  fcfsStartTime: string,
  setStageNext: React.Dispatch<React.SetStateAction<string | null>>,
  setStageOver: React.Dispatch<React.SetStateAction<string | null>>
): Promise<void> {
  const currentTime = new Date();
  const interestStart = new Date(interestStartTime);
  const allocationStart = new Date(allocationStartTime);
  const fcfsStart = new Date(fcfsStartTime);

  if (currentTime < interestStart) {
    setStageNext("Interest");
    setStageOver(null);
  } else if (currentTime > interestStart && currentTime < allocationStart) {
    setStageNext("Allocation");
    setStageOver("Interest");
  } else if (
    currentTime > interestStart &&
    currentTime > allocationStart &&
    currentTime < fcfsStart
  ) {
    setStageNext("FCFS");
    setStageOver("Allocation");
  }

  return Promise.resolve();
}
