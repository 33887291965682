import Sider from "antd/es/layout/Sider";
import Text from "../components/Text";
import { Button, Col, Menu, MenuProps, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../redux/reducers";
import IconClose from "../assets/Svgs/IconClose.svg";
import IconOpen from "../assets/Svgs/IconOpen.svg";
import { closeSidebar, openSidebar } from "../redux/reducers";
import iconTwiter from "../assets/Svgs/SocialTwitter.svg";
import iconTelegram from "../assets/Svgs/SocialTelegram.svg";
import iconYoutube from "../assets/Svgs/SocialYoutube.svg";
import iconSocial from "../assets/Svgs/SocialIcons.svg";
import iconInsta from "../assets/Svgs/SocialInsta.svg";
import iconFacebok from "../assets/Svgs/SocialFacebook.svg";
import iconLinkedin from "../assets/Svgs/SocialLinkedin.svg";
import iconDiscoard from "../assets/Svgs/SocialDiscoard.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { SelectComponent } from "../components/Header";
import { useAccount } from "wagmi";
import {
  IconOffer,
  IconAllocation,
  IconFAQ,
  IconOTC,
  IconProfile,
} from "../components/Icons";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
  } as MenuItem;
}

const SideBar = () => {
  const SideBar = useSelector(appState);
  const dispatch = useDispatch();

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  return (
    <Sider
      width={SideBar.MobileSider ? 278 : 70}
      style={{
        backgroundColor: "#0C0A1F",
        padding: SideBar.MobileSider ? "24px" : "24px 10px",
        borderRight: "1px solid rgba(255, 255, 255, 0.250)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.250)",
      }}
    >
      <SideBarMenu />
    </Sider>
  );
};

export default SideBar;

export const SideBarMenu = () => {
  const SideBar = useSelector(appState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { address } = useAccount();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [hasAddress, setHasAddress] = useState<boolean>(false);

  useEffect(() => {
    if (address) {
      setHasAddress(true);
    } else {
      setHasAddress(false);
    }
  }, [address]);

  const items: MenuItem[] = [
    getItem("Offers", "1", <IconOffer />, undefined, undefined, () => {
      navigate("/");
    }),
    getItem("OTC", "2", <IconOTC />, undefined, undefined, () => {
      navigate("/otc");
    }),
    getItem(
      "Allocation Calc",
      "3",
      <IconAllocation />,
      undefined,
      undefined,
      () => {
        navigate("/allocation");
      }
    ),
    getItem("FAQs & Help", "4", <IconFAQ />, undefined, undefined, () => {
      navigate("/faq");
    }),
  ];

  if (hasAddress) {
    items.push(
      getItem("My Account", "5", <IconProfile />, undefined, undefined, () => {
        navigate("/account");
      })
    );
  }

  return (
    <Row
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Col>
        {md && (
          <Row style={{ justifyContent: "flex-end" }}>
            {
              <img
                style={{ cursor: "pointer" }}
                src={SideBar.MobileSider ? IconClose : IconOpen}
                onClick={() => {
                  if (SideBar.MobileSider) {
                    dispatch(closeSidebar());
                  } else {
                    dispatch(openSidebar());
                  }
                }}
              />
            }
          </Row>
        )}
        {!md && (
          <Row
            style={{
              flexDirection: "column",
              gap: "4px",
              paddingLeft: "4px",
            }}
          >
            <Text size="xs" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
              Network
            </Text>
            {address && <SelectComponent width={"100%"} />}
          </Row>
        )}
        <Row style={{ justifyContent: "center", marginTop: "21px" }}>
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            // inlineCollapsed={SideBar.MobileSider}
            items={items}
          />
        </Row>
      </Col>
      {SideBar.MobileSider && (
        <Col>
          {!md && (
            <Row>
              {/* <Button
                size="large"
                style={{ width: "100%", marginBottom: "36px" }}
              >
                Buy $ACQ Token
              </Button> */}
            </Row>
          )}
          {/* <Row
            style={{
              flexDirection: "column",
              justifyContent: "center",
              gap: "24px",
              borderRadius: "16px",
              backgroundColor: "rgba(255, 255, 255, 0.02)",
              padding: "23px 24px",
            }}
          >
            <Text
              size="sm"
              style={{
                color: "rgba(255, 255, 255, 0.50)",
                textAlign: "center",
              }}
            >
              Our Socials
            </Text>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "16px",
                flexWrap: "wrap",
                cursor: "pointer",
              }}
            >
              <img src={iconTwiter} />
              <img src={iconTelegram} />
              <img src={iconYoutube} />
              <img src={iconSocial} />
              <img src={iconInsta} />
              <img src={iconFacebok} />
              <img src={iconLinkedin} />
              <img src={iconDiscoard} />
            </Col>
          </Row> */}
          <Row style={{}}>
            <Text
              size="xs"
              style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.50" }}
            >
              © 2024 Acquire.Fi | All rights reserved.
            </Text>
          </Row>
        </Col>
      )}
    </Row>
  );
};
