import { Button, Col, Form, Input, Row } from "antd";
import Text from "../../components/Text";
import BIT from "../../assets/BIT.png";
import SOL from "../../assets/SOL.png";
import SUI from "../../assets/SUI.png";
import ORD from "../../assets/ORD.png";
import { setUserAddresses } from "../../api/ApiCalls";
import { shortenString } from "../../pages/MyAccount";

import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { useSelector } from "react-redux";
import { userState } from "../../redux/reducers";
import { useEffect, useState } from "react";

const MyAddresses = () => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const { user } = useSelector(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    if (
      user?.bitcoin_address ||
      user?.bitcoin_ordinals_address ||
      user?.solana_address ||
      user?.sui_address
    ) {
      setEdit(false);
    } else {
      setEdit(true);
    }
  }, []);

  const onFinish = async (values: any) => {
    console.log("Bitcoin", values?.bitcoin);
    console.log("solana", values?.solana);
    console.log("ordinal", values?.ordinal);
    console.log("sui", values?.sui);

    const payload: UserAddresses = {
      bitcoin_address: values?.bitcoin,
      bitcoin_ordinals_address: values?.ordinal,
      solana_address: values?.solana,
      sui_address: values?.sui,
    };

    try {
      setLoading(true);
      const res = await setUserAddresses(payload, user?.id);
      if (res) {
        console.log("response address addition", res);
      }
      setLoading(false);
      window.location.reload();
    } catch (err) {
      console.log("Error While set Addresses", err);
      setLoading(false);
    }
  };
  return (
    <Row
      style={{
        width: "100%",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250",
        backgroundColor: "#120F2D",
        padding: "16px",
        marginTop: "24px",
      }}
    >
      <Row style={{ width: "100%", justifyContent: "space-between" }}>
        <Text size="md" style={{ fontWeight: "700" }}>
          My Addresses
        </Text>
        {edit === false && (
          <Text
            size="sm"
            style={{
              fontWeight: "500",
              color: "#33FFFF",
              cursor: "pointer",
            }}
            onClick={() => {
              setEdit(true);
            }}
          >
            Edit Addresses
          </Text>
        )}
      </Row>
      <Row style={{ marginTop: "16px", width: "100%" }}>
        <Form
          style={{ width: "100%" }}
          onFinish={onFinish}
          initialValues={{
            bitcoin: user?.bitcoin_address,
            solana: user?.solana_address,
            ordinal: user?.bitcoin_ordinals_address,
            sui: user?.sui_address,
          }}
        >
          <Row style={{ width: "100%" }} gutter={20}>
            <Col span={xs ? 24 : 12}>
              <FormInput
                title="Bitcoin Address"
                icon={BIT}
                placeholder="Enter Bitcoin Address"
                name="bitcoin"
                edit={edit}
                address={user?.bitcoin_address}
              />
            </Col>
            <Col span={xs ? 24 : 12}>
              <FormInput
                title="Solana Address"
                icon={SOL}
                placeholder="Enter Solana Address"
                name="solana"
                edit={edit}
                address={user?.solana_address}
              />
            </Col>
          </Row>
          <Row style={{ width: "100%" }} gutter={20}>
            <Col span={xs ? 24 : 12}>
              <FormInput
                title="Ordinals Address"
                icon={ORD}
                placeholder="Enter Ordinals Address"
                name="ordinal"
                edit={edit}
                address={user?.bitcoin_ordinals_address}
              />
            </Col>
            <Col span={xs ? 24 : 12}>
              <FormInput
                title="SUI Address"
                icon={SUI}
                placeholder="Enter SUI Address"
                name="sui"
                edit={edit}
                address={user?.sui_address}
              />
            </Col>
          </Row>
          <Row style={{ justifyContent: "flex-end", width: "100%" }}>
            {edit === true && (
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "16px", width: xs ? "100%" : "" }}
                loading={loading}
              >
                Save Address(es)
              </Button>
            )}
          </Row>
        </Form>
      </Row>
    </Row>
  );
};

export default MyAddresses;

interface FormInput {
  title: string;
  icon: string;
  placeholder: string;
  name: string;
  address: string;
  edit: boolean;
}

const FormInput = (props: FormInput) => {
  const { icon, placeholder, title, name, address, edit } = props;
  return (
    <Row
      style={{
        gap: "12px",
        // alignItems: "center",
        width: "100%",
      }}
    >
      <img src={icon} style={{ width: "44px", height: "44px" }} />
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          flexGrow: 1,
        }}
      >
        <Text size="xs" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
          {title}
        </Text>
        {
          <Form.Item name={name}>
            {edit ? (
              <Input
                placeholder={placeholder}
                size="large"
                className="Customize-Text"
              ></Input>
            ) : (
              <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
                {address ? shortenString(address) : "Add Address"}
              </Text>
            )}
          </Form.Item>
        }
      </Col>
    </Row>
  );
};

interface UserAddresses {
  sui_address: string;
  solana_address: string;
  bitcoin_address: string;
  bitcoin_ordinals_address: string;
}
