import React, { useEffect } from "react";
import "./App.scss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { Layout, Row } from "antd";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, userState } from "./redux/reducers";
import { GetChallenge, WalletLogin } from "./api/ApiCalls";
import SideBar from "./components/SideBar";
import Header from "./components/Header";
import IdoDetail from "./pages/idoDetail/IdoDetail";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import MyAccount from "./pages/MyAccount";
import VerifyUser from "./pages/VerifyUser";
import { useUser } from "./hooks";
import FAQ from "./pages/FAQ";
import AllocationsCalc from "./pages/AllocationsCalc";
import OTC from "./pages/OTC";
import SignlePageIdo from "./pages/SignlePageIdo";
import SingleTicketPage from "./pages/SingleTicketPage";

function App() {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const dispatch = useDispatch();
  const { token } = useSelector(userState);
  const user = useSelector(userState);
  const { getCurrentUser } = useUser();

  const HandleDissconnect = async () => {
    disconnect();
    dispatch(logout());
  };

  const SignMessage = async (message: string) => {
    try {
      const signature = await signMessageAsync({ message });
      return signature;
    } catch (error) {
      console.log("User Rejected the SignMessage:", error);
      HandleDissconnect();
      return null;
    }
  };

  const WalletConnectSign = () => {
    let challenge = "";
    if (address) {
      GetChallenge(address)
        .then((res) => {
          challenge = res?.data;
          SignMessage(res.data)
            .then((signature) => {
              const payload: SignedData = {
                wallet_address: address,
                signature: signature || "",
                challenge,
              };
              if (signature !== null) {
                WalletLogin(payload)
                  .then((value) => {
                    const payload = {
                      user: value.wallet_address,
                      isAuthenticated: true,
                      token: value.access_token,
                      refreshToken: value.refresh_token,
                    };
                    dispatch(login(payload));
                    localStorage.setItem("accessToken", value.access_token);
                    localStorage.setItem("refreshToken", value.refresh_token);

                    console.log("access_token", value.access_token);
                    console.log("refresh_token", value.refresh_token);
                  })
                  .catch((error) => {
                    console.log("walletlogin error>>>>", error);
                    throw error;
                  });
              } else {
                console.log("User rejected the signature request.");
              }
            })
            .catch((error) => {
              console.log("signmessage error", error);
              throw error;
            });
        })
        .catch((error: any) => {
          console.log("error while getchallenge ", error);
          HandleDissconnect();
        });
    }
  };

  useEffect(() => {
    if (address && user.isAuthenticated === false) {
      WalletConnectSign();
    } else if (!address && user.isAuthenticated === true) {
      HandleDissconnect();
    }
  }, [address]);

  useEffect(() => {
    if (token) {
      getCurrentUser();
    }
  }, [token]);

  return (
    <>
      <Router>
        <Layout
          style={{
            height: "100vh",
          }}
        >
          <Header />
          <Layout style={{ backgroundColor: "#0C0A1F" }}>
            {md && <SideBar />}
            <Layout
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                backgroundColor: "#0C0A1F",
              }}
            >
              <Routes>
                <Route path="*" element={<Home />} />
                <Route path="offer/:id/" element={<SignlePageIdo />} />
                {address && <Route path="/account" element={<MyAccount />} />}
                <Route path="/verify-user" element={<VerifyUser />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/allocation" element={<AllocationsCalc />} />
                <Route path="/otc" element={<OTC />} />
                <Route
                  path="/single-ticket/:id/"
                  element={<SingleTicketPage />}
                />
              </Routes>
            </Layout>
          </Layout>
        </Layout>
      </Router>
    </>
  );
}

export default App;

interface SignedData {
  wallet_address: string;
  signature: string;
  challenge: string;
}
