import axios from "../axios";
import { MyTickets } from "../../utils/MyTicketsTypes";

export const GetMyTickets = async () => {
  try {
    const endPoint = `api/allocations/`;
    const res = await axios.get<Array<MyTickets>>(endPoint);
    console.log("My Tickets API Res !", res.data);
    return res.data;
  } catch (err) {
    console.log("error while Get MyTickets", err);
    return Promise.reject(err);
  }
};

export const GetSingleTickets = async (id: string) => {
  try {
    const endPoint = `api/allocations/${id}/`;
    const res = await axios.get<MyTickets>(endPoint);
    console.log("Single Tickets API Res !", res.data);
    return res.data;
  } catch (err) {
    console.log("error while Get Single Tickets", err);
    return Promise.reject(err);
  }
};
