import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IdoListType } from "../../utils/types";

const initialState: IdoListType[] = [];

const stageOrder = ["pending", "interest", "allocation", "fcfs", "ended"];

const sortByStage = (a: IdoListType, b: IdoListType) => {
  return stageOrder.indexOf(a.stage) - stageOrder.indexOf(b.stage);
};

export const idoSlice = createSlice({
  name: "ido",
  initialState,
  reducers: {
    setido: (state, action: PayloadAction<IdoListType[]>) => {
      // state = action.payload;
      // return state;
      state = action.payload.slice().sort(sortByStage);
      return state;
    },
  },
});

export const { setido } = idoSlice.actions;

export const idoState = (state: RootState) => state.ido;

export default idoSlice.reducer;
