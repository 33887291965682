import { Button, Col, ConfigProvider, Row, Tooltip } from "antd";
import Text from "./Text";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ValuesFormatDecimalAndCommas, determineStages } from "../Services/";
import Countdown, { CountdownForTileCard } from "../components/CountDown";
import { IdoListType } from "../utils/types";
import { useEffect, useState } from "react";

interface IdoCardsProp {
  coverImg: string;
  iconImg: string;
  TokenName: string;
  status?: boolean;
  startDate: string;
  endDate: string;
  raiseTotal: string;
  valuation?: string;
  category: string;
  id: any;
  stage: string;
  tokenSymbol: string;
  ido: IdoListType;
}

const IdoCards = (props: IdoCardsProp) => {
  const navigate = useNavigate();
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  const [stageNext, setStageNext] = useState<string | null>(null);
  const [stageOver, setStageOver] = useState<string | null>(null);

  const {
    TokenName,
    coverImg,
    endDate,
    iconImg,
    raiseTotal,
    startDate,
    status,
    valuation,
    category,
    stage,
    tokenSymbol,
    ido,
  } = props;

  const handleNextOverStage = async () => {
    await determineStages(
      ido.interest_start,
      ido.allocation_start,
      ido.fcfs_start,
      setStageNext,
      setStageOver
    );
  };

  useEffect(() => {
    if (stage === "pending") {
      handleNextOverStage();
    }
  }, []);
  return (
    <Row className="cards"
      style={{
        width: xxl
          ? "506px"
          : xl
          ? "351px"
          : lg
          ? "300px"
          : md
          ? "411px"
          : "100%",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250)",
      }}
    >
      <Row
        style={{
          width: "100%",
          height: "220px",
          backgroundImage: `url(${coverImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
        }}
      ></Row>
      <Row
        style={{
          width: "100%",
          borderTop: "1px soild rgba(255, 255, 255, 0.250)",
          padding: "16px",
          backgroundColor: "#120F2D",
          borderBottomRightRadius: "24px",
          borderBottomLeftRadius: "24px",
          gap: "16px",
        }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Col style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            {/* <img
              src={iconImg}
              style={{ height: "42px", width: "42px", borderRadius: "50%" }}
            /> */}
            <div
              style={{
                height: "42px",
                width: "42px",
                borderRadius: "50%",
                backgroundImage: `url(${iconImg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Row
              style={{
                // alignSelf: "stretch",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Text size="md" style={{ fontWeight: "700", fontSize: "18px" }}>
                {TokenName}
              </Text>
              {/* <Text size="xs" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
                #{category}
              </Text> */}
            </Row>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
              {processString(stage)}
            </Text>
            <ClockCircleOutlined
              style={{
                height: "18px",
                width: "18px",
                color: status ? "#33FFFF" : "rgba(255, 255, 255, 0.50)",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            padding: "16px",
            borderRadius: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
          }}
        >
          <Row
            style={{
              width: "100%",
              gap: "8px",
              paddingBottom: "16px",
              marginBottom: "16px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.250)",
            }}
          >
            <TextRow
              label="RAISE TOTAL"
              value={`${ValuesFormatDecimalAndCommas(
                raiseTotal
              )} ${tokenSymbol}`}
            />
            {/* <TextRow label="VALAUATION" value={`$${raiseTotal}`} /> */}
          </Row>
          <Row style={{ width: "100%", gap: "8px", marginBottom: "16px" }}>
            {ido.stage !== "pending" ? (
              <>
                <TextRowDate label="Starting" Date={startDate} stage={stage} />
                <TextRowDate label="Ending" Date={endDate} stage={stage} />
              </>
            ) : (
              <>
                {" "}
                <Text
                  size="sm"
                  style={{ color: "rgba(255, 255, 255, 0.50)", width: "100%" }}
                >
                  {/* {`${
                    stageOver !== null ? `The ${stageOver} stage is over. ` : ``
                  }`} */}
                  {`${stageNext !== null ? `Next stage is ${stageNext}` : ``}`}
                </Text>
                {ido.stage === "pending" && (
                  <>
                    <Row
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <ClockCircleOutlined
                          style={{
                            color: "rgba(255, 255, 255, 0.50)",
                          }}
                        />
                        <Text
                          size="sm"
                          style={{ color: "rgba(255, 255, 255, 0.50)" }}
                        >
                          Starting
                        </Text>{" "}
                      </Col>
                      <Text
                        size="sm"
                        style={{
                          color: "rgba(255, 255, 255, 0.50)",
                        }}
                      >
                        {stageNext !== null && (
                          <Countdown
                            endDateString={
                              stageNext === "Interest"
                                ? ido.interest_start
                                : stageNext === "Allocation"
                                ? ido.allocation_start
                                : stageNext === "FCFS"
                                ? ido.fcfs_start
                                : ""
                            }
                          />
                        )}
                      </Text>
                    </Row>
                  </>
                )}
              </>
            )}
          </Row>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: "#28204E",
                  colorPrimaryHover: "#28204E",
                  primaryColor: "#33FFFF",
                  colorTextLightSolid: "#33FFFF",
                  controlHeightLG: 40,
                },
              },
            }}
          >
            <Button
              className="overview-offer-btn"
              type="primary"
              size="large"
              style={{ width: "100%", boxShadow: "unset", color:'white', paddingBlock:'24px' }}
              onClick={() => navigate("/offer/" + props.id)}
            >
              View Order
            </Button>
          </ConfigProvider>
        </Row>
      </Row>
    </Row>
  );
};

export default IdoCards;

interface TextRowProp {
  label: string;
  value: string;
}

const TextRow = (props: TextRowProp) => {
  const { label, value } = props;
  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
        {label}
      </Text>{" "}
      <Text size="sm">{value}</Text>
    </Row>
  );
};

interface TextRowDateProp {
  label: string;
  Date: string;
  stage?: string;
}

const TextRowDate = (props: TextRowDateProp) => {
  const { label, Date, stage } = props;

  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Tooltip title={StageChecker(stage)}>
          <ClockCircleOutlined
            style={{
              color: "rgba(255, 255, 255, 0.50)",
            }}
          />
        </Tooltip>
        <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
          {label}
        </Text>{" "}
      </Col>
      <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
        <CountdownForTileCard endDateString={Date} />
      </Text>
    </Row>
  );
};

const CardButton = () => {
  return <Button></Button>;
};

function StageChecker(stage: string | undefined) {
  if (stage === "interest") {
    return "Next Stage is Allocation";
  } else if (stage === "allocation") {
    return "Next Stage is FCFS";
  }
}

function processString(input: string): string {
  if (input === "ended") {
    return "Closed";
  } else {
    if (input.length === 0) return input; // Handle empty string case
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}
