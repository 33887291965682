import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetUser } from "../api/ApiCalls";
import { login as _login, logout as _logout, setUser } from "../redux/reducers";

const useUser = () => {
  const dispatch = useDispatch();

  const getCurrentUser = async () => {
    try {
      const res = await GetUser();
      dispatch(setUser(res.data));
    } catch (error) {
      console.error("error getCurrentUser", error);
    }
  };

  const logout = () => {
    dispatch(_logout());
    localStorage.removeItem("token");
  };

  return {
    logout,
    getCurrentUser,
  };
};

export default useUser;
