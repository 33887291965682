import Icon from "@ant-design/icons";
import { SVGProps } from "react";

type IconProps = {
  fill?: string;
};

// Create a separate Icon component
export const IconWallet = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.75 0.999999C11.75 0.585786 11.4142 0.25 11 0.25H4.25C3.83579 0.25 3.5 0.585786 3.5 0.999999C3.5 1.41421 3.83579 1.75 4.25 1.75L10.25 1.75L10.25 7.75C10.25 8.16421 10.5858 8.5 11 8.5C11.4142 8.5 11.75 8.16421 11.75 7.75V0.999999ZM1.53033 11.5303L11.5303 1.53033L10.4697 0.469669L0.46967 10.4697L1.53033 11.5303Z" fill="black"/>
</svg>

      
    )}
  />
);

export const IconOffer = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 1 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m6.626 14.433-.673-.9a.72.72 0 0 0-.527-.306c-.2-.007-.4.08-.56.246l-.38-.34.367.34c-.96 1.027-1.7.947-2.053.807-.36-.14-.967-.6-.967-2.08V4.693c0-2.96.853-3.86 3.647-3.86h5.04c2.793 0 3.646.9 3.646 3.86V12.2c0 1.473-.606 1.933-.966 2.08-.354.14-1.087.22-2.054-.807-.16-.173-.353-.266-.566-.246a.733.733 0 0 0-.534.306l-.673.9c-.347.467-.853.734-1.373.734s-1.027-.267-1.374-.734ZM5.473 12.22c.493.027.96.287 1.273.707l.674.9c.326.433.826.433 1.153 0l.673-.9c.32-.42.78-.68 1.28-.707.494-.027.987.18 1.347.567.507.54.84.606.953.56.16-.067.334-.454.334-1.147V4.693c0-2.406-.42-2.86-2.647-2.86H5.48c-2.227 0-2.647.454-2.647 2.86V12.2c0 .7.173 1.087.333 1.147.114.046.447-.02.954-.56.36-.367.806-.567 1.266-.567h.087Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M5.333 5.167a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h5.333c.274 0 .5.226.5.5 0 .273-.226.5-.5.5H5.333ZM6 7.833a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h4c.273 0 .5.227.5.5 0 .274-.227.5-.5.5H6Z"
        />
      </svg>
    )}
  />
);

export const IconOTC = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 1 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillOpacity={0.5}
          d="M3.007 3.667C3.007 3.3 3.3 3 3.674 3h.006c.367 0 .667.3.667.667a.67.67 0 0 1-1.34 0ZM11.673 12.333c0-.366.293-.666.666-.666h.007c.367 0 .667.3.667.666a.67.67 0 0 1-1.34 0Z"
        />
        <path
          fill="currentColor"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M.813 3.667A2.84 2.84 0 0 1 3.647.833 2.84 2.84 0 0 1 6.48 3.667 2.835 2.835 0 0 1 3.647 6.5 2.84 2.84 0 0 1 .813 3.667Zm1 0a1.832 1.832 0 1 0 3.667 0 1.832 1.832 0 1 0-3.667 0ZM11.314 15.167a1.832 1.832 0 0 1-1.834-1.834v-2c0-1.013.82-1.833 1.834-1.833h2c1.013 0 1.833.82 1.833 1.833v2c0 1.014-.82 1.834-1.833 1.834h-2Zm-.834-3.834v2c0 .46.374.834.834.834h2c.46 0 .833-.374.833-.834v-2a.834.834 0 0 0-.833-.833h-2a.834.834 0 0 0-.834.833Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillOpacity={0.5}
          d="M6.213 13.167A1.81 1.81 0 0 1 4.5 11.98a1.821 1.821 0 0 1 .506-2.027l5.327-4.66c.32-.28.327-.66.233-.92a.82.82 0 0 0-.78-.54H8a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h1.786A1.81 1.81 0 0 1 11.5 4.02c.273.72.073 1.513-.507 2.027l-5.327 4.66a.827.827 0 0 0-.233.92c.1.26.353.54.78.54H8c.273 0 .5.226.5.5 0 .273-.227.5-.5.5H6.213Z"
        />
      </svg>
    )}
  />
);

export const IconAllocation = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 1 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M6.667 15.167C3.047 15.167 1.5 13.62 1.5 10V6C1.5 2.38 3.047.833 6.667.833h2.666C12.953.833 14.5 2.38 14.5 6v4c0 3.62-1.547 5.167-5.167 5.167H6.667ZM2.5 6v4c0 3.073 1.093 4.167 4.167 4.167h2.666c3.074 0 4.167-1.094 4.167-4.167V6c0-3.073-1.093-4.167-4.167-4.167H6.667C3.593 1.833 2.5 2.927 2.5 6Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M6 7.22c-.827 0-1.5-.673-1.5-1.5v-.667c0-.826.673-1.5 1.5-1.5h4c.827 0 1.5.674 1.5 1.5v.667c0 .827-.673 1.5-1.5 1.5H6Zm-.5-2.167v.667c0 .273.227.5.5.5h4c.273 0 .5-.227.5-.5v-.667c0-.273-.227-.5-.5-.5H6c-.273 0-.5.227-.5.5Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillOpacity={0.5}
          d="M5.18 9.893a.66.66 0 0 1-.407-.613.657.657 0 0 1 .194-.473c.026-.027.06-.06.1-.08a.37.37 0 0 1 .113-.06.666.666 0 0 1 .873.36c.034.08.054.166.054.253 0 .18-.074.347-.2.473a.66.66 0 0 1-.727.14ZM7.634 9.753a.66.66 0 0 1-.194-.473.657.657 0 0 1 .194-.473c.026-.027.06-.06.1-.08a.371.371 0 0 1 .113-.06.648.648 0 0 1 .513 0 .659.659 0 0 1 .36.36c.034.08.054.166.054.253 0 .18-.074.347-.2.473a.656.656 0 0 1-.467.194.66.66 0 0 1-.473-.194ZM10.513 9.893a.658.658 0 0 1-.36-.36.658.658 0 0 1-.047-.253.658.658 0 0 1 .194-.473.69.69 0 0 1 .94 0c.126.126.2.3.2.473 0 .18-.074.347-.2.473a.665.665 0 0 1-.727.14ZM4.967 12.42a.666.666 0 0 1-.2-.473.678.678 0 0 1 .3-.554.373.373 0 0 1 .113-.06.466.466 0 0 1 .127-.04.678.678 0 0 1 .686.28l.06.12.04.12c.007.047.014.087.014.134 0 .18-.074.346-.2.473a.656.656 0 0 1-.467.193.66.66 0 0 1-.473-.193ZM7.634 12.42a.666.666 0 0 1-.2-.473c0-.174.073-.347.2-.474a.684.684 0 0 1 .6-.18c.04.007.086.02.126.04.04.014.08.034.114.06.033.02.066.054.1.08.126.127.193.3.193.474a.66.66 0 0 1-.66.666.66.66 0 0 1-.473-.193ZM10.3 12.42a.66.66 0 0 1-.194-.473c0-.174.067-.347.194-.474a.676.676 0 0 1 .94 0c.126.127.193.3.193.474a.66.66 0 0 1-.193.473.658.658 0 0 1-.214.14.655.655 0 0 1-.727-.14Z"
        />
      </svg>
    )}
  />
);

export const IconFAQ = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 1 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M.833 8A7.173 7.173 0 0 1 8 .833 7.173 7.173 0 0 1 15.166 8 7.173 7.173 0 0 1 8 15.167 7.173 7.173 0 0 1 .833 8Zm1 0c0 3.4 2.767 6.167 6.167 6.167S14.166 11.4 14.166 8 11.4 1.833 8 1.833A6.174 6.174 0 0 0 1.833 8Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillOpacity={0.5}
          d="M7.5 8.667V5.333c0-.273.227-.5.5-.5s.5.227.5.5v3.334c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5ZM7.746 11.28a.77.77 0 0 1-.22-.14.688.688 0 0 1-.14-.22.664.664 0 0 1-.053-.253c0-.087.02-.174.053-.254a.77.77 0 0 1 .14-.22.771.771 0 0 1 .22-.14.667.667 0 0 1 .507 0c.08.034.153.08.22.14a.77.77 0 0 1 .14.22c.033.08.053.167.053.254 0 .086-.02.173-.053.253a.688.688 0 0 1-.14.22.77.77 0 0 1-.22.14.664.664 0 0 1-.253.053.664.664 0 0 1-.254-.053Z"
        />
      </svg>
    )}
  />
);

export const IconProfile = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="currentColor"
        viewBox="0 1 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillOpacity={0.5}
          fillRule="evenodd"
          d="M8.053 7.747a.362.362 0 0 0-.12 0C6 7.687 4.54 6.167 4.54 4.293A3.465 3.465 0 0 1 8 .833a3.465 3.465 0 0 1 3.46 3.46 3.448 3.448 0 0 1-3.333 3.454h-.074ZM5.54 4.293a2.442 2.442 0 0 0 2.367 2.454 1.1 1.1 0 0 1 .213 0 2.456 2.456 0 0 0 2.34-2.454A2.467 2.467 0 0 0 8 1.833a2.467 2.467 0 0 0-2.46 2.46ZM4.5 14.033c-.927-.613-1.434-1.453-1.434-2.366 0-.914.507-1.76 1.434-2.38 2-1.327 5.24-1.327 7.226 0 .92.613 1.434 1.453 1.434 2.366 0 .914-.507 1.76-1.434 2.38-1 .667-2.306 1-3.613 1s-2.62-.333-3.613-1Zm-.434-2.36c0 .567.354 1.114.987 1.534 1.66 1.113 4.46 1.113 6.12 0 .64-.427.987-.974.987-1.547 0-.567-.354-1.113-.987-1.533-1.66-1.107-4.46-1.107-6.12 0-.64.426-.987.973-.987 1.546Z"
          clipRule="evenodd"
        />
      </svg>
    )}
  />
);

export const IconOfferButton = (props: SVGProps<SVGSVGElement>) => (
  <Icon
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="currentColor"
        viewBox="0 0 16 16"
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m6.626 14.433-.673-.9a.72.72 0 0 0-.527-.306c-.2-.007-.4.08-.56.246l-.38-.34.367.34c-.96 1.027-1.7.947-2.053.807-.36-.14-.967-.6-.967-2.08V4.693c0-2.96.853-3.86 3.647-3.86h5.04c2.793 0 3.646.9 3.646 3.86V12.2c0 1.473-.606 1.933-.966 2.08-.354.14-1.087.22-2.054-.807-.16-.173-.353-.266-.566-.246a.733.733 0 0 0-.534.306l-.673.9c-.347.467-.853.734-1.373.734s-1.027-.267-1.374-.734ZM5.473 12.22c.493.027.96.287 1.273.707l.674.9c.326.433.826.433 1.153 0l.673-.9c.32-.42.78-.68 1.28-.707.494-.027.987.18 1.347.567.507.54.84.606.953.56.16-.067.334-.454.334-1.147V4.693c0-2.406-.42-2.86-2.647-2.86H5.48c-2.227 0-2.647.454-2.647 2.86V12.2c0 .7.173 1.087.333 1.147.114.046.447-.02.954-.56.36-.367.806-.567 1.266-.567h.087Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M5.333 5.167a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h5.333c.274 0 .5.226.5.5 0 .273-.226.5-.5.5H5.333ZM6 7.833a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h4c.273 0 .5.227.5.5 0 .274-.227.5-.5.5H6Z"
        />
      </svg>
    )}
  />
);
